.search {
  width: 100vw;
  height: 100vh;
  padding-top: 300px;
}

.search .bar-search {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.search .bar-search form {
  width: 40%;
  display: flex;
}

.search .input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search button {
  color: black !important;
}

.search .bar-search input {
  flex-grow: 1;
  min-width: 0;
  height: 50px;
  padding: 12px 42px;
  padding-right: 110px;
  border: none;
  border-radius: 100px;
  -webkit-box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.426);
  -moz-box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.426);
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.426);
}

.search .bar-search input:focus {
  outline: none;
  border: none !important;
}

.search .icon-search {
  position: absolute;
  margin-left: 31%;
  left: 0;
}

.search .icon-search img {
  width: 20px;
  height: 20px;
}

.search .input-container button {
  font-size: 16px;
}

.search .size-numb {
  font-size: 13px;
  font-weight: 500;
}

.search .search-numb,
.search .search-letter {
  position: absolute;
  margin-right: 31%;
  background-color: transparent;
  border: 0;
  right: 0;
}

.search .search-letter {
  margin-right: 33%;
}

.search .active {
  color: #1e2336;
  font-weight: 900;
}

.search .active .size-numb {
  font-weight: 900;
}

.search .search-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.search .search-list button {
  width: 150px;
  border: solid 1px #ccc;
  border-radius: 100px;
  background-color: transparent;
  padding: 12px;
  margin: 6px;
}

@media (max-width: 1399.98px) {
  .search .bar-search form {
    width: 60% !important;
  }

  .search .search-numb,
  .search .search-letter {
    margin-right: 22%;
  }

  .search .search-letter {
    margin-right: 26%;
  }

  .search .icon-search {
    margin-left: 21%;
  }

  .search .bar-search input {
    padding-right: 120px;
  }

  .search {
    padding-top: 300px;
  }
}

@media (max-width: 1199.98px) {
  .search .bar-search form {
    width: 70% !important;
  }

  .search .search-numb,
  .search .search-letter {
    margin-right: 17%;
  }

  .search .search-letter {
    margin-right: 21%;
  }

  .search .icon-search {
    margin-left: 16%;
  }

  .search {
    padding-top: 300px;
  }
}

@media (max-width: 991.98px) {
  .search .bar-search form {
    width: 80% !important;
  }

  .search .search-numb,
  .search .search-letter {
    margin-right: 12%;
  }

  .search .search-letter {
    margin-right: 17%;
  }

  .search .icon-search {
    margin-left: 12%;
  }

  .search .bar-search input {
    padding-right: 105px;
  }

  .search {
    padding-top: 200px;
  }
}

@media (max-width: 767.98px) {
  .search .bar-search form {
    width: 80% !important;
  }

  .search .search-numb,
  .search .search-letter {
    margin-right: 13%;
  }

  .search .search-letter {
    margin-right: 19%;
  }

  .search .icon-search {
    margin-left: 12%;
  }

  .search .bar-search input {
    padding-right: 100px;
  }

  .search {
    padding-top: 150px;
  }
}

@media (max-width: 575.98px) {
  .search .bar-search form {
    width: 90% !important;
  }

  .search .search-numb,
  .search .search-letter {
    margin-right: 8%;
  }

  .search .search-letter {
    margin-right: 17%;
  }

  .search .icon-search {
    margin-left: 8%;
  }

  .search .bar-search input {
    padding-right: 95px;
  }

  .search {
    padding-top: 150px;
  }
}
