.search-page {
    width: 100vw;
    height: 100vh;
}

.search-page .bar-search {
    width: 100%;
    border-bottom: solid 1px #ccc;
}

.search-page form {
    width: 100%;
    height: 45px;
    display: flex;
}

.search-page input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
}

.search-page .input-container {
    width: 100%;
}

.search-page .search-letter,
.search-page .search-numb,
.search-page .icon-search {
    position: absolute;
    margin-top: 12px;
}

.search-page .icon-search {
    margin-left: 12px;
}

.search-page .search-letter,
.search-page .search-numb {
    right: 0;
    margin-right: 20px;
}

.search-page .search-letter {
    margin-right: 70px;
}

.search-page .btn-back img {
    width: 20px;
    height: 20px;
}

.search-page .btn-back {
    border: 0;
    width: 50px;
    background-color: transparent;
}

.search-page .body-search {
    padding: 24px;
}

.search-page .body-search p {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 12px;
    background-color: #f9f9f9;
    text-align: left;
    cursor: pointer;
    color: black !important;
    margin-bottom: 12px;
}

.search-page .body-search p:hover {
    background-color: #eee;
}

.not-find {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0px !important;
    background-color: transparent !important;
    text-align: left !important;
    cursor: none !important;
    color: black !important;
    margin-bottom: 0px !important;
}

.verse-result {
    background-color: #fff3b0;
    padding: 5px;
    margin: 3px 0;
    cursor: pointer;
  }
  
  .book-title {
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
  }
  