.ct-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.one,
.two,
.three,
.four {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.one img,
.two img,
.three img,
.four img {
  width: 24px;
  height: 24px;
}

.one h1,
.two h1,
.three h1,
.four h1 {
  margin-top: 12px;
}

.active img {
  width: 26px;
  height: 26px;
}

.active h1 {
  margin-top: 12px;
  font-weight: 900;
}

.two {
  margin: 0 48px;
}

.four {
  margin-left: 48px;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #eaeaea;
  display: flex;
  justify-content: space-between;
}

.info {
  width: 100%;
  padding: 0 20px;
  margin-top: 24px;
}

.banner.hide {
  display: none;
}

.info h1 {
  font-size: 18px;
  font-weight: 900;
}

.info p {
  font-weight: 400;
  margin-top: 2px;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #383838;
  width: 100%;
  animation: progressAnimation 30s linear forwards;
}

@media (max-width: 575.98px) {
  .box {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}

@keyframes progressAnimation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
