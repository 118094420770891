main {
  width: 100%;
  height: 100%;
  /* background: rgb(30, 35, 54) !important;
  background: -moz-linear-gradient(
    36deg,
    rgba(30, 35, 54, 1) 0%,
    rgba(82, 95, 143, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    36deg,
    rgba(30, 35, 54, 1) 0%,
    rgba(82, 95, 143, 1) 100%
  ) !important;
  background: linear-gradient(
    36deg,
    rgba(30, 35, 54, 1) 0%,
    rgba(82, 95, 143, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e2336",endColorstr="#525f8f",GradientType=1);
} */
}
/*.hedline {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hedline h1 {
  width: 550px;
  font-size: 32px;
  font-weight: 900;
  color: #3030da;
  line-height: 40px;
}

.hedline p {
  width: 650px;
  font-size: 16px;
  margin-top: 24px;
  line-height: 24px;
}

.ct-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.box {
  width: 250px;
  height: 250px;
  border-radius: 15px;
  border: solid 1px rgb(195, 195, 195);
  cursor: pointer;
  border-style: dashed;
}

.box:hover {
  -webkit-box-shadow: 0px 0px 43px -10px rgba(220, 220, 220);
  -moz-box-shadow: 0px 0px 43px -10px rgba(220, 220, 220);
  box-shadow: 0px 0px 43px -10px rgb(220, 220, 220);
}

.one,
.two,
.three,
.four {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.one img,
.two img,
.three img,
.four img {
  width: 28px;
  height: 28px;
}

.one h1,
.two h1,
.three h1,
.four h1 {
  font-size: 22px;
  font-weight: 900;
  margin-top: 16px;
}

.one p,
.two p,
.three p,
.four p {
  font-size: 14px;
  margin: 0 24px;
  margin-top: 18px;
  line-height: 18px;
}

.two {
  margin: 0 48px;
}

.four {
  margin-left: 48px;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
}

.info {
  width: 100%;
  padding: 0 20px;
  margin-top: 24px;
}

.banner.hide {
  display: none;
}

.info h1 {
  font-size: 18px;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}

.info p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 2px;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: #4caf50;
  width: 100%;
  animation: progressAnimation 30s linear forwards;
}

@media (max-width: 575.98px) {
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .banner .info {
    width: 100%;
    padding: 0 20px;
    margin-top: 24px;
  }

  .hedline {
    display: none;
  }

  .ct-box {
    flex-direction: column;
    padding: 100px 0;
    margin: 0;
  }

  .ct-box .two {
    margin: 24px 0;
  }

  .ct-box .four {
    margin-left: 0;
    margin-top: 24px;
  }

  .App {
    height: auto;
  }
}

@keyframes progressAnimation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
} */
