.download-page {
  width: 100vw;
  height: 100vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.img-download {
  width: 100px;
  height: 100px;
  background-color: #f2f2f2;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-download img {
  width: 28px;
  height: 28px;
}

.desc-download {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desc-download h2 {
  width: 490px;
  font-size: 42px;
  font-weight: bold;
  margin-top: 48px;
}

.desc-download p {
  width: 320px;
  font-size: small;
  margin-top: 24px;
}

.btn-ct {
  display: flex;
  margin-top: 48px;
}

.btn-apps {
  width: 250px;
  height: 50px;
  background-color: #f2f2f2;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.divider-app {
  padding: 0 24px;
}

.android,
.ios {
  cursor: pointer;
  transition: ease-in 0.2s;
}

.android:hover,
.ios:hover {
  scale: 1.1;
}

@media (max-width: 575.98px) {
  .desc-download h2 {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    margin-top: 48px;
  }
}
