.donate-page {
  width: 100vw;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.img-donate {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-donate img {
  width: 300px;
}

.desc-donate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desc-donate h2 {
  width: 490px;
  font-size: 42px;
  font-weight: bold;
  margin-top: 24px;
}

.desc-donate p {
  width: 350px;
  margin-top: 24px;
}

.btn-ct {
  display: flex;
  margin-top: 48px;
}

.btn-apps {
  width: 250px;
  height: 50px;
  background-color: #f2f2f2;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.info-donate {
  margin-top: 24px;
}

@media (max-width: 575.98px) {
  .desc-donate h2 {
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    margin-top: 48px;
  }
}

