nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    font-weight: 300;
}

.left-side {
    display: flex;
    align-items: center;
    margin-left: 24px;
}

.left-side img,
.left-side p {
    cursor: pointer;
}

.left-side img {
    width: 24px;
    margin-right: 8px;
}

.right-side {
    display: flex;
    align-items: center;
    padding-right: 24px;
}

.right-side a {
    font-size: 14px;
    color: black;
    text-decoration: none;
}

.right-side p {
    padding: 0 12px;
}