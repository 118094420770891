.bible-page {
  padding: 20px;
}

.bible-page h1 {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.chapter {
  margin-bottom: 20px;
}

.chapter h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.verses {
  padding-left: 20px;
}

.verse {
  margin-bottom: 5px;
}

.highlight {
  font-weight: bold;
  color: rgb(14, 88, 227);
}
