.books-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.testament {
  width: 100%;
}

.books-button {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  background-color: #f9f9f9;
  text-align: left;
  cursor: pointer;
  color: black !important;
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

.books-button:hover {
  background-color: #eee;
}

.testament h2 {
  padding: 24px 0;
  font-size: 18px;
  font-weight: bold;
}