.hymns {
  width: 100vw;
  height: 100vh;
  padding: 24px 80px 24px 24px;
}

.hymns-nav {
  width: fit-content;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f8f8f8;
  padding: 15px;
  border-left: 1px solid #ccc;
}

.nav-button {
  width: 24px;
  height: 24px;
  min-height: 24px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black !important;
}

.nav-button.active {
  background-color: #007bff;
  color: #fff !important;
}

.hymns-groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hymn-button {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  background-color: #f9f9f9;
  text-align: left;
  cursor: pointer;
  color: black !important;
}

.hymn-button:hover {
  background-color: #eee;
}
