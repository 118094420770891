.result {
    text-align: center;
    padding: 24px;
}

.result h1 {
    font-size: medium;
    margin-bottom: 24px;
}

.result p {
    font-size: 16px;
}